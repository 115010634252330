import React, { useEffect, useState } from "react";
import RichText from "components/rich-text";
import getImagePath from "helpers/get-image-path";
import Mail from "assets/images/mail.png";
import Phone from "assets/images/phone.png";
import { getCalculatedContainer } from "./responsiveHandle";

import { 
  contactHero, chContent, chMedia, chText,
  chTitle, chBoxes, firstBox, secondBox, contactUrl
} from "./style.mod.scss";

const ContactHero = ({ data }) => {

  const { title, hero, content } = data;

  if ((typeof window !== "undefined")) {
    var containerWidth = window.innerWidth;
  }
  
  const [dimensions, setDimensions] = useState(null);

  useEffect(() => {
    const { screenResolution, margin } = getCalculatedContainer();

    setDimensions(
      ((containerWidth - screenResolution) / 2) - margin
    );
  },[]);


  return (
    <div style={{ marginLeft: dimensions + "px" }} className={contactHero}>
      <div className={chContent}>
        <div className={chText}>
          <h1 className={chTitle}>{title}</h1>
          <RichText blocks={content} />
        </div>
        
        <div className={chBoxes}>
          <div className={firstBox}>
            <img src={Phone}></img>
            <a className={contactUrl} href="tel:+4932221093396">+49 32 221093396</a>
            <p>Mo-Sa: 9-22 Uhr</p>
          </div>
          <div className={secondBox}>
            <img src={Mail}></img>
            <a className={contactUrl} href="mail:hello@myolav.com">hello@myolav.com</a>
            <p>24/7</p>
          </div>
        </div>

      </div>
      <div className={chMedia}>
        <img src={getImagePath(hero, true)}></img>
      </div>
    </div>
  );
};

export default ContactHero;
