export const getCalculatedContainer = () =>  {
  let extraMargin = 8;
  
  if ((typeof window !== "undefined")) {
    var browserWidth = window.innerWidth;
  }
  
  const containerResArray = [
    { 
      screenWidth: 7679, container: 1600 
    },
    { 
      screenWidth: 1920, container: 1600 
    },
    { 
      screenWidth: 1400, container: 1240 
    },
    { 
      screenWidth: 1200, container: 1140 
    },
    { 
      screenWidth: 992, container: 960 
    },
    { 
      screenWidth: 768, container: 720 
    },
    { 
      screenWidth: 576, container: 540 
    },
  ];

  let result = [];

  containerResArray.forEach((element, index ) => {
    const { screenWidth, container } = element;
    const lastScreenWidth = containerResArray[index-1]?.screenWidth;

    if ( (browserWidth >= screenWidth) && (browserWidth <= lastScreenWidth) ) {
      result = { 
        screenResolution: container, 
        margin: extraMargin 
      };

      return result;
    }
    return;
  });

  return result;
};
